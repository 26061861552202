import { AppStateStore } from './AppState.store'
import { CommunicationStateStore } from './Communication.store'
import { OnboardingStateStore } from './Onboarding.store'
import { TranslationsStore } from './Translations.store'

export class RootStore {
  AppState: AppStateStore
  TranslationsState: TranslationsStore
  OnboardingState: OnboardingStateStore
  CommunicationState: CommunicationStateStore

  constructor() {
    this.AppState = new AppStateStore(this)
    this.TranslationsState = new TranslationsStore(this)
    this.OnboardingState = new OnboardingStateStore(this)
    this.CommunicationState = new CommunicationStateStore(this)
  }
}

export const rootStore = new RootStore()
