import { action, makeAutoObservable } from 'mobx'
// import * as translations from '../constants/languages/index'

export type Language =
  | 'en'
  | 'da'
  | 'de'
  | 'es'
  | 'bg'
  | 'cs'
  | 'el'
  | 'fi'
  | 'fr'
  | 'hu'
  | 'it'
  | 'nl'
  | 'no'
  | 'pl'
  | 'pt'
  | 'ro'
  | 'sk'
  | 'sl'
  | 'sv'

interface TranslationsInterface {
  pageTitle: string
  documentTypePage: {
    header: string
    selectCountry: string
    proceed: string
    chooseDocument: string
    ResidentPermit: string
    EUResidentPermit: string
    TravelTitle: string
    DriverLicense: string
    Passport: string
    IdentityCard: string
    inputPlaceholder: string
  }
  generalConditionsPage: {
    header: string
    termsAndConditions: string
    privacyPolicy: string
    required: string
    proceed: string
    conditionTermsHeading: string
    conditionPrivacyHeading: string
  }
  otpPage: {
    header: string
    headerVerifying: string
    verify: string
  }
  signDocumentPage: {
    header: string
    checkboxLabel: string
    proceed: string
  }
  klarnaPrivacyPolicyPage: {
    privacyPolicyHeader: string
    firstParagraph: string
    firstParagraphPartOne: string
    serviceProvider: string
    firstParagraphPartTwo: string
    secondParagraph: string
    thirdParagraph: string
    fourthParagraph: string
    consent: string
    proceed: string
    required: string
    whatYouNeed: string
    wellLitPlace: string
    originalDocument: string
    stableConnection: string
    onlineBanking: string
    cameraAccess: string
  }
  userDataPage: {
    header: string
    email: string
    phone: string
    proceed: string
  }
  videoOnboardingPage: {
    header: string
    wellLitPlace: string
    originalDocument: string
    wifi: string
    start: string
  }
  progressPage: {
    heading: string
    wait: string
    waitMore: string
  }
  jitPayWelcomePage: {
    heading: string
    descriptionPartOne: string
    serviceProvider: string
    descriptionPartTwo: string
    idData: string
    termsAndConditions: string
    privacyPolicy: string
    proceed: string
    stepOne: string
    stepTwo: string
    stepThree: string
    stepThreeDesc: string
  }
  jitPayOnboardingPage: {
    heading: string
    signingHeader: string
    signingContent: string
    idVerifyHeader: string
    idVerifyContent: string
    micropaymentHeader: string
    micropaymentContent: string
    proceed: string
  }
  jitPayVideoOnboardingPage: {
    header: string
    lightHeading: string
    lightContent: string
    netHeading: string
    netContent: string
    deviceHeading: string
    deviceContent: string
    oneCameraHeading: string
    oneCameraContent: string
    proceed: string
  }
  micropaymentInfoJitpayPage: {
    header: string
    content: string
    warning: string
    proceed: string
    idData: string
  }
  micropaymentInfoIkanoPage: {
    content: string
    tink: string
    proceed: string
  }
  ikanoWelcomePage: {
    heading: string
    descriptionPartOne: string
    serviceProvider: string
    descriptionPartTwo: string
    idData: string
    termsAndConditions: string
    privacyPolicy: string
    proceed: string
    stepOne: string
    stepTwo: string
    stepThree: string
    stepThreeDesc: string
  }
  ikanoPolicies: {
    privacy: string
    terms: string
  }
  ikanoCompletePage: {
    submit: string
    message: string
    message1_1: string
    message1_2: string
    message1_3: string
    message2: string
    message3: string
  }
  signDocumentIkanoPage: {
    header: string
    checkboxLabel: string
    proceed: string
  }
  introKontomatikPage: {
    header: string
    intro: string
    accountInformation: string
    names: string
    address: string
    ownership: string
    verificationPurposes: string
    proceed: string
  }
  addressUploadPageKontomatik: {
    verifying: string
    header: string
    description: string
    confirmation: string
    bankStatementContains: string
    yourAddress: string
    proceed: string
    dragAndDrop: string
    chooseFile: string
    pko: {
      header: string
      confirmation: string
      whereToFindStatements: string
      selectPDF: string
      tutorial: {
        desktop: {
          version: string
          step1: string
          step2: string
          step3: string
          step4: string
          step5: string
        }
        mobile: {
          version: string
          step1: string
          step2: string
          step3: string
          step4: string
          step5: string
          step6: string
          step7: string
        }
      }
    }
  }
  pixelSpeak: {
    verifying: string
    header: string
    description: string
    documentsList: string[]
    documentTypes: Array<{ value: string; label: string }>
    proceed: string
    dragAndDrop: string
    dropHere: string
    chooseFile: string
    filetypes: string
    maxFilesize: string
    maxFilesizeValue: string
    fileErrorSize: string
    fileErrorType: string
    complete: string
  }
  addressVerificationIkano: {
    header: string
    description: string
    documentsList: string[]
    proceed: string
  }
  addressVerificationTwoSidedIkano: {
    header: string
    descriptionPartOne: string
    documentsList: string[]
    descriptionPartTwo: string
    proceed: string
  }
  AddressUploadFrontSideIkano: {
    verifying: string
    header: string
    description: string
    descriptionConfirm: string
    takePhoto: string
    retakePhoto: string
    proceed: string
    complete: string
    errorMessage: string
    errorRetry: string
  }
  AddressUploadBackSideIkano: {
    verifying: string
    header: string
    description: string
    descriptionConfirm: string
    takePhoto: string
    retakePhoto: string
    proceed: string
    complete: string
    errorMessage: string
    errorRetry: string
  }
}

export class TranslationsStore {
  rootStore
  // translations: TranslationsInterface = translations.en
  language = 'en'

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setTranslations(language: Language): void {
    this.language = language
    // this.translations = translations[language] ?? translations.en
  }
}
