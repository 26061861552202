import styled from 'styled-components'

export const FileInputContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  height: 90px;
  /* aspect-ratio: 4/1; */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  position: relative;

  border: 2px dashed #2cb5ae;
  border-radius: 4px;
  cursor: pointer;

  transition: 0.3s background;

  &:hover {
    background: #f6f6f6;
  }

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  div {
  }

  p {
    margin: 0;
    font-size: 16px;
    font-family: 'Futura LT';
    font-weight: 600;
    color: #2cb5ae;
  }
`
