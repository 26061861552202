import { Route, Switch } from 'wouter'
import { LoadingSpinner } from 'authflow-ui-engine'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { DemoPage } from '../pages/DemoPage/DemoPage'
import { PrivacyPolicyPage } from '../pages/PrivacyPolicyPage'
import { ContentWrap } from '../styles/generic.styles'
import { StoreContext } from './App'

const LoadWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)

  return (
    <div className="content-router">
      <ContentWrap>
        <Switch>
          <Route path="/" component={DemoPage} />

          <Route path="/demo" component={DemoPage} />

          <Route path="/privacy-policy" component={PrivacyPolicyPage} />

          <Route>
            <LoadWrap>
              <LoadingSpinner />
            </LoadWrap>
          </Route>
        </Switch>
      </ContentWrap>
    </div>
  )
})
