import React, { useRef } from 'react'
import styled from 'styled-components'
import { CirclePlusIcon } from '../../components/Icons/CirclePlus'
import { FileInputContainer } from './FileInput.styles'
import { FileIcon } from '../../components/Icons/File'

interface FileInputProps {
  onChange?: (file: File | File[]) => void
  accept?: string
  multiple?: boolean
  name?: string
}

export const FileInput: React.FC<FileInputProps> = ({
  onChange,
  accept,
  multiple,
  name,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [file, setFile] = React.useState<File | null>(null)

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    setFile(file)

    onChange && onChange(file)
  }

  return (
    <FileInputContainer>
      {file && file?.name ? (
        <FileIcon size="45px" color="#2cb5ae" />
      ) : (
        <CirclePlusIcon size="45px" color="#2cb5ae" />
      )}

      {file && <p>{file.name}</p>}

      <input
        type="file"
        id={name || 'file'}
        name={name || 'file'}
        accept={accept || '.png, .jpg, .jpeg'}
        multiple={multiple}
        ref={inputRef}
        onChange={handleFileChange}
      />
    </FileInputContainer>
  )
}
