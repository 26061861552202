import React from 'react'
import styled from 'styled-components'

export interface IconProps {
  size: string
  color?: string
  margin?: string
}

export const Icon = styled.div.attrs({ className: 'sc-icon' })<IconProps>`
  display: inline-block;
  height: ${(props) => (props.size ? props.size : '24px')};
  aspect-ratio: 1 / 1;
  margin: ${(props) => (props.margin ? props.margin : '')};

  svg {
    width: 100%;
    height: 100%;
  }
`

export const CirclePlusIcon = ({ size, color, margin }: IconProps) => {
  return (
    <Icon size={size} margin={margin}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
      >
        <circle fill={color || '#000'} cx="25" cy="25" r="25" />
        <line
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="25"
          y1="13"
          x2="25"
          y2="38"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="37.5"
          y1="25"
          x2="12.5"
          y2="25"
        />
      </svg>
    </Icon>
  )
}
